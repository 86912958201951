import { Action, State, StateContext } from '@ngxs/store';
import {
  ResetRejectedHarvest,
  ResetRejectedReasonsAndStems,
  SetRejectedGradingStation,
  SetRejectedReasonsAndStems,
} from '@store/mobile/rejected-stock/rejected-stock.actions';
import { RejectedStockStateModel } from '@store/mobile/rejected-stock/rejected-stock-state.model';
import { Injectable } from '@angular/core';

@State<Partial<RejectedStockStateModel>>({
  name: 'rejectedStock',
  defaults: {
    rejectedStems: {},
  },
})
@Injectable()
export class RejectedStockState {
  @Action(SetRejectedGradingStation)
  public setGradingStation(
    ctx: StateContext<Partial<RejectedStockStateModel>>,
    { gradingStation }: SetRejectedGradingStation,
  ): void {
    ctx.patchState({
      gradingStation,
    });
  }

  @Action(SetRejectedReasonsAndStems)
  public setRejectedStems(
    ctx: StateContext<Partial<RejectedStockStateModel>>,
    { reasonId, amount }: SetRejectedReasonsAndStems,
  ): void {
    const state = ctx.getState();
    const rejectedStems = { ...state.rejectedStems };

    if (rejectedStems[reasonId]) {
      rejectedStems[reasonId] = rejectedStems[reasonId] + amount;
    } else {
      rejectedStems[reasonId] = amount;
    }

    ctx.patchState({
      ...state,
      rejectedStems,
    });
  }

  @Action(ResetRejectedReasonsAndStems)
  public resetRejectedStems(
    ctx: StateContext<Partial<RejectedStockStateModel>>,
    { reasonId }: ResetRejectedReasonsAndStems,
  ): void {
    const state = ctx.getState();
    const rejectedStems = { ...state.rejectedStems };

    if (rejectedStems[reasonId]) {
      delete rejectedStems[reasonId];
    }

    ctx.patchState({
      ...state,
      rejectedStems,
    });
  }

  @Action(ResetRejectedHarvest)
  public reset(ctx: StateContext<Partial<RejectedStockStateModel>>): void {
    ctx.setState({
      rejectedStems: {},
    });
  }
}
